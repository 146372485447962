module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-simple-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"charleston-cu","short_name":"charleston CU","start_url":"/","background_color":"#FFFFFF","theme_color":"#9e012e","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"11c6d5f23551b01d2aa7b8244e2a4177"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
